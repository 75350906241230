import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './utils/AuthContext';
import './App.css';
import Home from './pages/Home';
import Layout from './components/Layout';
import MySites from './pages/MySites';
import ProtectedRoute from './utils/ProtectedRoute';
import Edit from './pages/Edit';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route 
              path="/my-sites" 
              element={
                <ProtectedRoute>
                  <MySites />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/edit/:slug" 
              element={
                <ProtectedRoute>
                  <Edit />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
