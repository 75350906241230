import React from 'react';
import { Link } from 'react-router-dom';

function SuccessMessage({ createdSiteUrl, sitename, resetForm }) {
  return (
    <div className="success-message">
      <img className='success-image' src={`https://shill-space-dev.s3.eu-central-1.amazonaws.com/${sitename}/assets/images/social-image.png`} alt="shill.space logo" />
      <h2>Success</h2>
      <p>Your new shill.space is now available at: <a href={createdSiteUrl} target="_blank" rel="noopener noreferrer">{createdSiteUrl}</a></p>
      <div>
        <h3>Share and get free marketing</h3>
        <p>1. Set your X profile link to <a href={createdSiteUrl} target="_blank" rel="noopener noreferrer">{createdSiteUrl}</a></p>
        <p>2. Share your shill.space on X, include the image above, and get a free repost from Moralis</p>
      </div>
      <div>
        <a href={`https://x.com/intent/post?text=We+just+claimed+our+shill.space+by+%40moraliscom+🔥+%0A+%0A+Check+it+out+here%3A+${encodeURIComponent(createdSiteUrl)}`} target="_blank" rel="noopener noreferrer"><button className="submit-btn">Share on X</button></a>
      </div>
      <div>
        <Link to={`/my-sites`}><button className='edit-btn'>Edit site</button></Link>
      </div>
    </div>
  );
}

export default SuccessMessage;