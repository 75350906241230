import React, { useState } from 'react';
import { useAuth } from '../utils/AuthContext';
import Modal from './Modal';
import AuthForm from './AuthForm';

function LoginButton() {
  const { session, signOut } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  return (
    <>
      <div className="login-wrapper">
        {session ? (
          <a className="login-link" onClick={handleLogout}>Logout</a>
        ) : (
          <a className="login-link" onClick={() => setShowAuthModal(true)}>Login</a>
        )}
      </div>

      {showAuthModal && !session && (
        <Modal onClose={() => setShowAuthModal(false)}>
          <AuthForm 
            isModal={true}
            onSuccess={() => setShowAuthModal(false)}
          />
        </Modal>
      )}
    </>
  );
}

export default LoginButton;

