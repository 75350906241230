import React, { useState } from 'react';
import { useAuth } from '../utils/AuthContext';
import { supabase } from '../utils/supabaseClient';

function AuthForm({ onSuccess, isModal = false }) {
  const { signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (!isOtpSent) {
        // Send OTP
        const { error } = await signIn(email);
        if (error) throw error;
        setIsOtpSent(true);
      } else {
        // Verify OTP
        const { error } = await supabase.auth.verifyOtp({
          email,
          token: otp,
          type: 'email'
        });
        if (error) throw error;
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`auth-form ${isModal ? 'modal' : ''}`}>
      <h2>{isOtpSent ? 'Enter Code' : 'Sign In'}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email"
          required
          disabled={isOtpSent}
        />
        {isOtpSent && (
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter the code sent to your email"
            required
          />
        )}
        <button type="submit" disabled={loading}>
          {loading 
            ? 'Loading...' 
            : (isOtpSent ? 'Verify Code' : 'Send Magic Link')}
        </button>
      </form>
      {!isOtpSent && (
        <p className="help-text">
          We'll send you a one-time code to log in.
        </p>
      )}
      {error && (
        <p className="error-message">{error}</p>
      )}
      {isOtpSent && (
        <p className="help-text">
          Check your email for the login code.
        </p>
      )}
    </div>
  );
}

export default AuthForm;
