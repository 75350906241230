import React from 'react';
import CustomLink from './CustomLink';

function Form({ formData, setFormData, handleSubmit, isLoading, submitButtonText = "Create Website", existingImageUrl, editMode }) {
  const handleInputChange = (e, index) => {
    const { name, value, files, type, checked } = e.target;
    
    if (name === 'logo') {
      setFormData({ ...formData, logo: files[0] });
    } else if (name.startsWith('customLinks')) {
      const updatedLinks = [...formData.customLinks];
      updatedLinks[index][name.split('.')[1]] = value;
      setFormData({ ...formData, customLinks: updatedLinks });
    } else if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const addCustomLink = () => {
    setFormData({
      ...formData,
      customLinks: [...formData.customLinks, { name: '', url: '' }],
    });
  };

  const removeCustomLink = (index) => {
    const updatedLinks = formData.customLinks.filter((_, i) => i !== index);
    setFormData({ ...formData, customLinks: updatedLinks });
  };

  return (
    <div>
        <h2>Submit your coin info</h2>
        <form onSubmit={handleSubmit}>
        <label>Coin Name</label>
        <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Coin Name"
            required
        />
        <label>Symbol</label>
        <input
            type="text"
            name="symbol"
            value={formData.symbol}
            onChange={handleInputChange}
            placeholder="Symbol"
            required
        />
        <label>Logo</label>
        {existingImageUrl && <img src={existingImageUrl} className="form-logo" alt="Existing Logo" />}
        <input
            type="file"
            name="logo"
            onChange={handleInputChange}
            accept="image/*"
            required={!editMode}
        />
        {formData.blockchain !== 'Pulsechain' && (
          <div className='checkbox-container'>
            <label>
              <input 
                type="checkbox"
                name="showPriceAndLiquidity"
                checked={formData.showPriceAndLiquidity}
                onChange={handleInputChange}
              />
              Show realtime market cap and liquidity
            </label>
          </div>
        )}
        <div className='checkbox-container'>
          <label>
            <input 
              type="checkbox"
              name="showChart"
              checked={formData.showChart}
              onChange={handleInputChange}
            />
            Include price chart (beta)
          </label>
        </div>
        <label>Description</label>
        <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Description"
            required
        />
        <label>Moralis Link (Chart & Price)</label>
        <input
        type="url"
        name="moralisLink"
        disabled
        value={formData.moralisLink}  
        placeholder="Moralis Link"
        required
        />
        <label>Website</label>
        <input
            type="url"
            name="websiteLink"
            value={formData.websiteLink}
            onChange={handleInputChange}
            placeholder="Website"
        />
        <label>X (Twitter) Link</label>
        <input
            type="url"
            name="xLink"
            value={formData.xLink}
            onChange={handleInputChange}
            placeholder="X (Twitter) Link"
        />
        <label>Telegram Link</label>
        <input
            type="url"
            name="telegramLink"
            value={formData.telegramLink}
            onChange={handleInputChange}
            placeholder="Telegram Link"
        />
        <label>Tiktok Link</label>
        <input
            type="url"
            name="tiktokLink"
            value={formData.tiktokLink}
            onChange={handleInputChange}
            placeholder="Tiktok Link"
        />
        {formData.customLinks.map((link, index) => (
            <CustomLink
            key={index}
            link={link}
            index={index}
            handleInputChange={handleInputChange}
            removeCustomLink={removeCustomLink}
            />
        ))}
        <div className="button-container">
            <button type="button" onClick={addCustomLink} className="add-link-btn">Add Custom Link</button>
        </div>
        <button type="submit" disabled={isLoading}>
            {isLoading ? 'Loading...' : submitButtonText}
        </button>
        </form>
    </div>
  );
}

export default Form;
