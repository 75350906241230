export const SITE_PREFIX = 'https://shill.space/';

export const BLOCKCHAIN_OPTIONS = [
  'Arbitrum',
  'Avalanche',
  'Base',
  'Binance',
  'Ethereum',
  'Optimism',
  'Polygon',
  'Pulsechain',
  'Solana',
];