import React from 'react';
import { Link } from 'react-router-dom';
import LoginButton from './LoginButton';
import logo from '../header-icon.svg';
import { useAuth } from '../utils/AuthContext';

function Layout({ children, handleMenuLogin, resetForm }) {
  const { session } = useAuth();  // Get session from AuthContext

  return (
    <div className="container">
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" onClick={resetForm} />
          </Link>
        </div>
        <div className="nav-links">
          {session && (
            <>
              <Link to="/" className="nav-link">Home</Link>
              <Link to="/my-sites" className="nav-link">My Sites</Link>
            </>
          )}
          <div className="login-button-container">
            <LoginButton setLoginStep={handleMenuLogin} session={session}/>
          </div>
        </div>
      </nav>
      {children}
    </div>
  );
}

export default Layout;