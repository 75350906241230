import React, { useState, useEffect } from 'react';
import { getUserWebsites, deleteWebsite } from '../utils/api';
import { useAuth } from '../utils/AuthContext';
import './MySites.css';
import { Link } from 'react-router-dom';

function MySites() {
    const { session } = useAuth();
    const [websites, setWebsites] = useState([]);

    useEffect(() => {
        getUserWebsites(session).then(setWebsites);
        console.log(websites);
    }, [session]);

    const handleEdit = (sitename) => {
        console.log(sitename);
    }
    const handleDelete = (sitename) => {
        deleteWebsite(sitename, session).then(() => {
            getUserWebsites(session).then(setWebsites);
        });
    }

  return (
    <div>
        <div className="my-sites-container">
            <h1>My Sites</h1>
            <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
            {websites.map((website) => (
                <tr key={website.id}>
                    <td>{website.name}</td>
                    <td><a href={`https://shill.space/${website.sitename}`} target="_blank" rel="noopener noreferrer">{`https://shill.space/${website.sitename}`}</a></td>
                    <td>
                        <Link to={`/edit/${website.sitename}`} className='table-button'>Edit</Link>
                        <button className='table-button' onClick={() => handleDelete(website.sitename)}>Delete</button>
                    </td>
                </tr>
            ))}
                </tbody>
            </table>
        </div>
    </div>
  );
}

export default MySites;