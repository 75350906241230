import React, { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext';
import InitialForm from '../components/InitialForm';
import Form from '../components/Form';
import SuccessMessage from '../components/SuccessMessage';
import { createWebsite } from '../utils/api';
import Modal from '../components/Modal';
import AuthForm from '../components/AuthForm';
import RecentlyCreated from '../components/RecentlyCreated';

function Home() {
  const { session } = useAuth();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [createdSiteUrl, setCreatedSiteUrl] = useState('');
  const [formData, setFormData] = useState({
    sitename: '',
    contractAddress: '',
    blockchain: '',
    showPriceAndLiquidity: true,
    showChart: true,
    name: '',
    symbol: '',
    description: '',
    moralisLink: '',
    xLink: '',
    telegramLink: '',
    customLinks: [{ name: '', url: '' }],
  });

  // Store form data before redirecting to auth
  const [pendingFormData, setPendingFormData] = useState(null);

  // Alternative approach using useEffect
  useEffect(() => {
    if (session && pendingFormData) {
      const proceedWithWebsiteCreation = async () => {
        setFormData(pendingFormData);
        setPendingFormData(null);
        await createWebsiteAndProceed();
      };
      proceedWithWebsiteCreation();
    }
  }, [session]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      if (!session) {
        // Store current form data and show auth modal
        setPendingFormData(formData);
        setShowAuthModal(true);
        return;
      }

      await createWebsiteAndProceed();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const createWebsiteAndProceed = async () => {
    setIsLoading(true);
    setError('');
    try {
      const data = await createWebsite(formData, session);
      setCreatedSiteUrl(data.url);
      setStep(3); // Move to success page after website creation
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle successful authentication
  const handleAuthSuccess = async () => {
    setShowAuthModal(false);
    // if (pendingFormData) {
    //   setFormData(pendingFormData);
    //   setPendingFormData(null);
    //   await createWebsiteAndProceed();
    // }
  };

  const resetForm = () => {
    setStep(1);
    setFormData({
      sitename: '',
      contractAddress: '',
      blockchain: '',
      showPriceAndLiquidity: true,
      showChart: true,
      name: '',
      symbol: '',
      description: '',
      moralisLink: '',
      xLink: '',
      telegramLink: '',
      customLinks: [{ name: '', url: '' }],
    });
    setCreatedSiteUrl('');
    setError('');
  };

  return (
    <div className="App">
      <div className="form-container">
        {error && <p className="error">{error}</p>}
        
        {step === 1 && (
          <InitialForm
            formData={formData}
            setFormData={setFormData}
            setStep={setStep}
            setError={setError}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}

        {step === 2 && (
          <Form
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        )}

        {step === 3 && (
          <SuccessMessage 
            createdSiteUrl={createdSiteUrl} 
            sitename={formData.sitename}
            resetForm={resetForm} 
          />
        )}
      </div>
      <RecentlyCreated />
      {showAuthModal && !session && (
        <Modal onClose={() => setShowAuthModal(false)}>
          <AuthForm 
            isModal={true}
            onSuccess={handleAuthSuccess}
          />
        </Modal>
      )}
    </div>
  );
}

export default Home;
