import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import AuthForm from '../components/AuthForm';
import Modal from '../components/Modal';

function ProtectedRoute({ children }) {
  const { session, loading } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState(false);

  if (loading) return null;
  
  if (!session) {
    return (
        <>
          <Modal onClose={() => setShowAuthModal(false)}>
            <AuthForm 
              isModal={true} 
              onSuccess={() => setShowAuthModal(false)} 
            />
          </Modal>
        </>
      );
  }

  return children;
}

export default ProtectedRoute;