const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchTokenMetadata = async (contractAddress, blockchain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/metadata`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ contractAddress, blockchain }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Failed to fetch token metadata. Please check the contract address and blockchain.');
    }
  } catch (error) {
    console.error('Error fetching token metadata:', error);
    throw error;
  }
};

export const fetchRecentlyCreated = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/recently-created`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error('Failed to fetch recently created sites');
      }
    } catch (error) {
      console.error('Error fetching recently created sites:', error);
      throw error;
    }
  };

export const checkAvailability = async (sitename) => {
  try {
    const response = await fetch(`${API_BASE_URL}/website/${sitename}/id`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Error checking availability');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const createWebsite = async (formData, session) => {
  try {
    const formDataToSend = new FormData();
    const { logo, ...formDataWithoutLogo } = formData;


    formDataToSend.append('info', JSON.stringify(formDataWithoutLogo));
    formDataToSend.append('logo', logo);

    const response = await fetch(`${API_BASE_URL}/website`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${session.access_token}`
      },
      body: formDataToSend
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Failed to create the site');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getUserWebsites = async (session) => {
  try {

    const response = await fetch(`${API_BASE_URL}/website`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${session.access_token}`
      }
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Failed to fetch user sites');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const deleteWebsite = async (sitename, session) => {
  try {

    const response = await fetch(`${API_BASE_URL}/website/${sitename}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${session.access_token}`
      }
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Failed to delete the site');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};