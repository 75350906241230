import React from 'react';

function CustomLink({ link, index, handleInputChange, removeCustomLink }) {
  return (
    <div className="custom-link">
      <input
        type="text"
        name={`customLinks.name`}
        value={link.name}
        onChange={(e) => handleInputChange(e, index)}
        placeholder={`Custom Link Name ${index + 1}`}
      />
      <input
        type="url"
        name={`customLinks.url`}
        value={link.url}
        onChange={(e) => handleInputChange(e, index)}
        placeholder={`Custom Link URL ${index + 1}`}
      />
      <button 
        type="button" 
        className="remove-link-btn" 
        onClick={() => removeCustomLink(index)}
      >
        ×
      </button>
    </div>
  );
}

export default CustomLink;