import React from 'react';
import { checkAvailability, fetchTokenMetadata } from '../utils/api';
import { SITE_PREFIX, BLOCKCHAIN_OPTIONS } from '../utils/constants';

function InitialForm({ formData, setFormData, setStep, setError, isLoading, setIsLoading }) {
  const handleSlugChange = (e) => {
    const newSlug = e.target.value.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase();
    setFormData({ ...formData, sitename: newSlug });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => {
      let newData = {
        ...prevData,
        [name]: value
      };
  
      if (name === "blockchain" && value === "Pulsechain") {
        newData.showPriceAndLiquidity = false;
      }
  
      return newData;
    });
  };

  const handleCheckAvailability = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      const data = await checkAvailability(formData.sitename);
      if (!data) {
        const metadata = await fetchTokenMetadata(formData.contractAddress, formData.blockchain);
        if (metadata) {
          setFormData(prevData => ({
            ...prevData,
            name: metadata.name || '',
            description: metadata.description || '',
            moralisLink: metadata.moralisLink || '',
            symbol: metadata.symbol || '',
          }));
          setStep(2);
        } else {
          setError('No token found. Please double check contract address and blockchain.');
        }
      } else {
        setError('This URL is not available. Please pick a different one.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Create your shill.space for free</h2>
      <p className="subtitle">A simple website generator for your token. Price data, charts, custom links, images and more.</p>
      <form onSubmit={handleCheckAvailability}>
        <div className="slug-input-container">
        <span className="slug-prefix">{SITE_PREFIX}</span>
        <input
          type="text"
          value={formData.sitename}
          onChange={handleSlugChange}
          placeholder="your-slug"
          required
          className="slug-input"
        />
      </div>
      <input
        type="text"
        name="contractAddress"
        value={formData.contractAddress}
        onChange={handleInputChange}
        placeholder="Contract Address"
        required
      />
      <select
        name="blockchain"
        value={formData.blockchain}
        onChange={handleInputChange}
        required
      >
        <option value="">Select Blockchain</option>
        {BLOCKCHAIN_OPTIONS.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <button type="submit" className="submit-btn" disabled={isLoading}>
        {isLoading ? <span className="spinner"></span> : 'Claim your shill.space domain'}
      </button>
    </form>
    </div>
  );
}

export default InitialForm;