import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import Form from '../components/Form';
import './MySites.css'; // Reuse the same styling

function Edit() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { session } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [imageErrors, setImageErrors] = useState({});
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    sitename: '',
    contractAddress: '',
    blockchain: '',
    showPriceAndLiquidity: true,
    showChart: true,
    name: '',
    symbol: '',
    description: '',
    moralisLink: '',
    xLink: '',
    telegramLink: '',
    customLinks: [{ name: '', url: '' }],
  });

  useEffect(() => {
    fetchWebsiteDetails();
  }, [slug]);

  const fetchWebsiteDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/${slug}`, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch website details');
      
      const data = await response.json();
      console.log(data);
      setFormData(prevData => ({
        ...prevData,
        blockchain: data.blockchain,
        contractAddress: data.contract_address,
        sitename: data.sitename,
        name: data.name,
        description: data.description,
        websiteLink: data.website,
        telegramLink: data.telegram_link,
        xLink: data.x_link,
        showPriceAndLiquidity: data.show_real_time_data,
        showChart: data.show_chart,
        existingImageUrl: `https://shill.space/${data.sitename}/${data.logo_url}`,
        moralisLink: data.moralis_link,
        symbol: data.symbol,
        customLinks: data.links ? data.links.map(link => JSON.parse(link)) : [{ name: '', url: '' }]
      }));
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const formDataToSend = new FormData();
      const { logo, ...formDataWithoutLogo } = formData;
  
  
      formDataToSend.append('info', JSON.stringify(formDataWithoutLogo));
      if(logo) {
        formDataToSend.append('logo', logo);
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/${slug}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: formDataToSend
      });

      if (!response.ok) throw new Error('Failed to update website');
      
      navigate('/my-sites');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div className="my-sites-page"><div className="my-sites-container">Loading...</div></div>;
  if (error) return <div className="my-sites-page"><div className="my-sites-container">Error: {error}</div></div>;
  if (!formData) return <div className="my-sites-page"><div className="my-sites-container">Website not found</div></div>;

  return (
    <div className="my-sites-page">
      <div className="my-sites-container">
        <h1>Edit Website (shill.space/{formData.sitename})</h1>
         <Form
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          submitButtonText="Update Website"
          existingImageUrl={formData.existingImageUrl}
          editMode={true}
        />
      </div>
    </div>
  );
}

export default Edit;
