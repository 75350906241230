import React, { useState, useEffect, useRef } from 'react';
import { fetchRecentlyCreated } from '../utils/api';
import './RecentlyCreated.css';

function RecentlyCreated() {

    const [sites, setSites] = useState([]);
    const [imageErrors, setImageErrors] = useState({});
    const carouselRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sites.length);
      };

    useEffect(() => {
        fetchRecentlyCreated().then(setSites);
    }, []);

    useEffect(() => {
        let interval;
        if (!isHovered && sites.length > 0) {
          interval = setInterval(nextSlide, 3000); // Change slide every 3 seconds
        }
        return () => {
          if (interval) {
            clearInterval(interval);
          }
        };
    }, [isHovered, sites.length]);

        useEffect(() => {
        if (carouselRef.current) {
          const slideWidth = carouselRef.current.offsetWidth;
          carouselRef.current.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
        }
    }, [currentIndex]);


    const getImageUrl = (sitename, format) => {
        return `https://shill-space-dev.s3.eu-central-1.amazonaws.com/${sitename}/assets/images/logo.${format}`;
      };
    
    const handleImageError = (sitename) => {
      setImageErrors(prev => {
        const newErrors = {...prev, [sitename]: (prev[sitename] || 0) + 1};
        if (newErrors[sitename] >= 4) { // If all formats have been tried
          setSites(current => current.filter(site => site.sitename !== sitename));
        }
        return newErrors;
      });
    };
    
    const getImageSrc = (sitename) => {
      const formats = ['webp', 'png', 'jpg', 'jpeg'];
      const errorCount = imageErrors[sitename] || 0;
      if (errorCount >= formats.length) {
        return 'path/to/fallback/image.png'; // Provide a fallback image
      }
      return getImageUrl(sitename, formats[errorCount]);
    };
    
      return (
        <div className="recently-created-container">
            <p>Recently shilled spaces</p>
          <div 
            className="carousel-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="carousel-wrapper">
              <div className="carousel" ref={carouselRef}>
                {sites.map((site) => (
                  <div key={site.sitename} className="carousel-item">
                    <a href={`https://shill.space/${site.sitename}`} target="_blank" rel="noopener noreferrer" className="carousel-item-content" >
                      <img 
                        src={getImageSrc(site.sitename)}
                        onError={() => handleImageError(site.sitename)}
                        alt={`${site.name} logo`}
                      />
                      <span>{site.name}</span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }

export default RecentlyCreated;
